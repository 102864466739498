<template>
  <div>
    <content-card :class="{ 'quick-start-selected': $store.state.quickStart.step === 5 }">
      <template v-slot:title>
        {{ hasSelectedClient ? 'Cliente' : 'Buscar clientes' }}
      </template>
      <template v-slot:actions>
        <div v-if="!hasSelectedClient">
          <sa-icon-button
            iconClass="text-xl bx bx-plus"
            tooltip="Crear nuevo cliente"
            @click="visibleDialogClientForm = true"/>
        </div>
        <div v-else>
          <sa-icon-button
            iconClass="text-xl bx bx-x"
            tooltip="Cancelar"
            @click="clearSelectedClient" />
        </div>
      </template>
      <div v-if="!hasSelectedClient">
        <el-select
          data-is-search="true"
          class="w-full"
          v-model="$state.order.clientId"
          remote
          clearable
          filterable
          autocomplete="off"
          placeholder="Teclea email, nombre o apellido"
          :remote-method="searchClient"
          :loading="loadingClients"
          @change="onSelectedClient">
          <el-option
            class="h-full border-b border-gray-150"
            v-for="client in filteredClients"
            :key="client.id"
            :label="client.fullName"
            :value="client.id">
            <div class="flex flex-col py-1">
              <span class="-mb-4 font-bold">{{ client.fullName }}</span>
              <span class="text-gray-500">{{ client.email }}</span>
            </div>
          </el-option>
        </el-select>
      </div>
      <div v-else
        class="py-2">
        <quick-start-tip
          title="Clientes"
          content="Aquí puedes elegir o ingresar al cliente que pertenece la Orden.
          Te recomendamos siempre ingresar el numero de telefono para poder utilizar las funciones de WhatsApp."
          placement="left"
          :bg-purple="false"
          :value="$store.state.quickStart.step === 5"
          @onNextStep="handleNextStep"
        >
          <div class="space-y-2">
            <dl class="flex justify-between">
              <dd>
                <span class="font-bold">Nombre</span>
              </dd>
              <dt>
                <span>{{ selectedClient.fullName }}</span>
              </dt>
            </dl>
            <dl class="flex justify-between">
              <dd>
                <span class="font-bold">Email</span>
              </dd>
              <dt>
                <span>{{ selectedClient.email }}</span>
              </dt>
            </dl>
            <dl class="flex justify-between">
              <dd>
                <span class="font-bold">Teléfono</span>
              </dd>
              <dt>
                <span>{{ selectedClient.countryCode }} {{ selectedClient.phone }}</span>
              </dt>
            </dl>
          </div>
        </quick-start-tip>
      </div>
    </content-card>
    <el-dialog
      :visible.sync="visibleDialogClientForm"
      :width="dialogClientWidth">
      <client-form
        :with-title="true"
        @onCancel="visibleDialogClientForm = false"
        @onSaved="onSavedClient"
        class="px-2" />
    </el-dialog>
  </div>
</template>
<script>
import { state } from '../store';
import ClientService from '@/services/ClientService';
import responsiveSize from '@/mixins/responsiveSize';
import _ from 'lodash';
import { QUICK_START_STORE } from '@/store/modules/quick-start/mutation-types';

export default {
  name: 'ClientBlock',
  components: {
    ContentCard: () => import('@/components/molecules/ContentCard.vue'),
    ClientForm: () => import('@/views/app/clients/ClientForm.vue'),
  },
  mixins: [
    responsiveSize,
  ],
  data() {
    return {
      loadingClients: false,
      visibleDialogClientForm: false,
      filteredClients: [],
      dialogClientWidth: '90%',
      selectedClient: { fullname: undefined, name: undefined, phone: undefined },
      showQuickStartTip: false,
    }
  },
  computed: {
    hasSelectedClient() {
      return this.$state.order.clientId;
    },
  },
  created() {
    window.addEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
    this.resizeDialogWidth();
    this.$state = state;
    setTimeout(() => {
      this.selectedClient = { fullname: undefined, name: undefined, phone: undefined, ...state.client };
    }, 1000);
    setTimeout(() => {
      this.showQuickStartTip = this.$store.state.quickStart.step === 5;
    }, 100);
  },
  destroyed() {
    window.addEventListener("resize", _.debounce(this.resizeDialogWidth, 200));
  },
  methods: {
    async searchClient(textSearch) {
      try {
        this.loadingClients = true;
        this.clearSelectedClient();
        this.filteredClients = textSearch !== '' ? await ClientService.getAll({
          textSearch,
          businessInfoId: this.$store.state.business.id,
        }) : [];
      }
      finally {
        this.loadingClients = false;
      }
    },
    onSelectedClient(clientId) {
      this.selectedClient = this.filteredClients.find(c => c.id == clientId);
    },
    clearSelectedClient() {
      this.filteredClients = [];
      this.selectedClient = null;
      this.$state.order.clientId = null;
    },
    onSavedClient(client) {
      this.selectedClient = client;
      this.$state.order.clientId = client.id;
      this.visibleDialogClientForm = false;
    },
    resizeDialogWidth() {
      this.dialogClientWidth = this.getPercentageWidth();
    },
    handleNextStep() {
      const step = this.$store.state.quickStart.step;
      this.$store.dispatch(QUICK_START_STORE, { step: step + 1 });
      if (step === 5) {
        this.showQuickStartTip = false;
      }
    },
  },
}
</script>
